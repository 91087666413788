import React, { useContext, useEffect, useState } from 'react';
import style from './CpointInfo.module.css'
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import CpointSec1Header from '../../../assets/img/cpoint_image/cpoint-sec1-header.png'
import CpointSec2Liner from '../../../assets/img/cpoint_image/cpoint-sec2-liner.png'
import CpointSec2Nephron from '../../../assets/img/cpoint_image/cpoint-sec2-nephron.png'
import CpointSec3Description1 from '../../../assets/img/cpoint_image/cpoint-sec3-description-1.png'
import CpointSec3Description2 from '../../../assets/img/cpoint_image/cpoint-sec3-description-2.png'
import CpointSec3Description3 from '../../../assets/img/cpoint_image/cpoint-sec3-description-3.png'
import MemberService from '../../../services/MemberService';

const CpointInfo = () => {
    const { auth } = useContext(AppContext)
    const history = useHistory()
    const [ci , setCI] = useState(false)
    const openCiBrowser = () => {
        try{
          window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
            url: "https://cpoint.or.kr/netzero/main.do"
          }, "");
        }catch(err){
          console.error(err)
        }
    }
    const openCiJoin = () => {
        try{
          window.nativeApi.execute(window.NativeApiMethod.OPEN_BROWSER, {
            url: "https://cpoint.or.kr/netzero/member/nv_memberRegistStep1.do"
          }, "");
        }catch(err){
          console.error(err)
        }
    }
    const getUserInfo = async() => {
        try{
            const result = await MemberService.getUserData()
            setCI(result.ci)
        }catch(err){
            setCI(false)
        }
    }
    useEffect(()=>{
        getUserInfo()
    },[])
    return (
        <div className="page-cpoint">
            {ci ?
            <div className={style["contents"]}>
                <div className={style["sec-01"]}>
                    <img className={style["sec-01-header"]} alt='sec1_header' src={CpointSec1Header}></img>
                </div>
                <div className={style["sec-02"]}>
                    <p className={style["title"]}>탄소중립포인트 <br />녹색생활 실천이란?</p>
                    <img className={style["line-img"]} alt='sec2_line-img' src={CpointSec2Liner}></img>
                    <p className={style["description"]}>
                        일반국민의 탄소중립 생활 실천문화 확산을 위하여<br />다양한 민간기업의 친환경활동 이용 시 이용실적에 따라 인센티브를 지원하는 제도 입니다.
                    </p>
                    <p className={style["description"]}>
                        2024년 2월 부터 네프론에 투입한 투명페트병,<br />캔 개수만큼 (연 최대 7만원) 탄소중립포인트를 받으실 수있습니다. 네프론을 사용하는 그 순간부터 이미 여러분은 환경을 지키는 탄소중립을 실천하게 됩니다.
                    </p>
                    <img className={style["nephron"]} alt='sec2_line-img' src={CpointSec2Nephron}></img>
                </div>
                <div className={style["sec-03"]}>
                    <p className={style["title"]}>탄소중립포인트 <br />받는방법</p>
                    <div className={style["use-description-container"]}>
                        <div className={style["description-container-items"]}>
                            <img src={CpointSec3Description2} alt={'sec3-description-2'}></img>
                            <div className={style["description"]}>
                                <p className={style["title"]}>01</p>
                                <p className={style["content"]}>‘탄소중립포인트 녹색생활실천’에 회원 가입</p>
                            </div>
                        </div>
                        <div className={style["description-container-items"]}>
                            <img src={CpointSec3Description3} alt={'sec3-description-3'}></img>
                            <div className={style["description"]}>
                                <p className={style["title"]}>02</p>
                                <p className={style["content"]}>네프론에 투명페트병 혹은 캔 투입</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style["sec-04"]}>
                    <div className={style["description"]}>
                        <p className={style["content"]}>매월 나의 투명페트병, 캔 투입 기록이 한국환경공단으로 자동 전송되어 탄소중립포인트가 적립됩니다.</p>
                    </div>
                </div>
                <div className={style["sec-05"]}>
                    <div className={style["description"]}>
                        <p className={style["title"]}>※ 유의사항</p>
                        <p className={style["content"]}>1. ‘탄소중립포인트 녹색생활 실천’은 한국환경 공단에서 제공하는 서비스로, 수퍼빈외 별도 홈페이지에 회원가입이 되어있어야 합니다.</p>
                        <p onClick={()=>{openCiJoin()}} className={style["content-description_1"]}>(탄소중립포인트 홈페이지 회원가입 바로가기)</p>
                        <p className={style["content"]}>2. ‘수퍼빈 포인트’와 ‘탄소중립포인트’는 별개의 서비스로, 각각 별도 적립됩니다.</p>
                        <p className={style["content"]}>3. ‘탄소중립포인트’는 ‘탄소중립포인트 녹색생활 실천’ 회원가입 이후부터 네프론에 투입한 투명페트병, 캔 개수를 무게로 환산하여 적립됩니다. (자원 1kg 당 100원 가치의 포인트 지급)</p>
                        <p className={style["content"]}>4. 수퍼빈 포인트가 아닌 씨앗으로 적립되는 네프론은 교육용 목적으로 운영됩니다. 따라서 씨앗적립형 네프론의 투입 기록은 탄소중립포인트와 연계되지 않습니다. </p>
                        <p className={style["content"]}>5. 탄소중립포인트에 대한 문의 및 제안사항은 한국환경공단으로 문의해주세요.</p>
                        <p className={style["content-description_2"]}>- 문의전화 : 1660-2030</p>
                        <p className={style["content-description_2"]}>- 이메일 : net-zero@keco.or.kr</p>
                    </div>
                </div>
                <div className={style["bottom-cpoint-homepage"]}>
                    <div className={style["content"]}>
                        <button className={style["ca-button"]} onClick={()=>{openCiBrowser()}}>탄소중립포인트 받으러 가기</button>
                    </div>
                </div>
            </div>
            :
            <div className={style["contents"]}>
                <div className={style["sec-01"]}>
                    <img className={style["sec-01-header"]} alt='sec1_header' src={CpointSec1Header}></img>
                </div>
                <div className={style["sec-02"]}>
                    <p className={style["title"]}>탄소중립포인트 <br />녹색생활 실천이란?</p>
                    <img className={style["line-img"]} alt='sec2_line-img' src={CpointSec2Liner}></img>
                    <p className={style["description"]}>
                        일반국민의 탄소중립 생활 실천문화 확산을 위하여<br />다양한 민간기업의 친환경활동 이용 시 이용실적에 따라 인센티브를 지원하는 제도 입니다.
                    </p>
                    <p className={style["description"]}>
                        2024년 2월 부터 네프론에 투입한 투명페트병,<br />캔 개수만큼 (연 최대 7만원) 탄소중립포인트를 받으실 수있습니다. 네프론을 사용하는 그 순간부터 이미 여러분은 환경을 지키는 탄소중립을 실천하게 됩니다.
                    </p>
                    <img className={style["nephron"]} alt='sec2_line-img' src={CpointSec2Nephron}></img>
                </div>
                <div className={style["sec-03"]}>
                    <p className={style["title"]}>탄소중립포인트 <br />받는방법</p>
                    <div className={style["use-description-container"]}>
                        <div className={style["description-container-items"]}>
                            <img src={CpointSec3Description1} alt={'sec3-description-1'}></img>
                            <div className={style["description"]}>
                                <p className={style["title"]}>01</p>
                                <p className={style["content"]}>아래 [수퍼빈 회원 인증하기] 버튼을 눌러 본인 인증</p>
                            </div>
                        </div>
                        <div className={style["description-container-items"]}>
                            <img src={CpointSec3Description2} alt={'sec3-description-2'}></img>
                            <div className={style["description"]}>
                                <p className={style["title"]}>02</p>
                                <p className={style["content"]}>‘탄소중립포인트 녹색생활실천’에 회원 가입</p>
                            </div>
                        </div>
                        <div className={style["description-container-items"]}>
                            <img src={CpointSec3Description3} alt={'sec3-description-3'}></img>
                            <div className={style["description"]}>
                                <p className={style["title"]}>03</p>
                                <p className={style["content"]}>네프론에 투명페트병 혹은 캔 투입</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style["sec-04"]}>
                    <div className={style["description"]}>
                        <p className={style["content"]}>매월 나의 투명페트병, 캔 투입 기록이 한국환경공단으로 자동 전송되어 탄소중립포인트가 적립됩니다.</p>
                    </div>
                </div>
                <div className={style["sec-05"]}>
                    <div className={style["description"]}>
                        <p className={style["title"]}>※ 유의사항</p>
                        <p className={style["content"]}>1. ‘탄소중립포인트 녹색생활 실천’은 한국환경 공단에서 제공하는 서비스로, 수퍼빈외 별도 홈페이지에 회원가입이 되어있어야 합니다.</p>
                        <p onClick={()=>{openCiJoin()}} className={style["content-description_1"]}>(탄소중립포인트 홈페이지 회원가입 바로가기)</p>
                        <p className={style["content"]}>2. ‘수퍼빈 포인트’와 ‘탄소중립포인트’는 별개의 서비스로, 각각 별도 적립됩니다.</p>
                        <p className={style["content"]}>3. ‘탄소중립포인트’는 ‘탄소중립포인트 녹색생활 실천’ 회원가입 이후부터 네프론에 투입한 투명페트병, 캔 개수를 무게로 환산하여 적립됩니다. (자원 1kg 당 100원 가치의 포인트 지급)</p>
                        <p className={style["content"]}>4. 수퍼빈 포인트가 아닌 씨앗으로 적립되는 네프론은 교육용 목적으로 운영됩니다. 따라서 씨앗적립형 네프론의 투입 기록은 탄소중립포인트와 연계되지 않습니다. </p>
                        <p className={style["content"]}>5. 탄소중립포인트에 대한 문의 및 제안사항은 한국환경공단으로 문의해주세요.</p>
                        <p className={style["content-description_2"]}>- 문의전화 : 1660-2030</p>
                        <p className={style["content-description_2"]}>- 이메일 : net-zero@keco.or.kr</p>
                    </div>
                </div>
                <div className={style["bottom"]}>
                    <div className={style["content"]}>
                        <p>탄소중립포인트를 받기 위한 첫 번째 단계!</p>
                        <button className={style["ca-button"]} onClick={()=>{history.push({pathname:'/certification/terms',query:{active:"certification"}},'/certification/terms')}}>1분 안에 수퍼빈 회원 인증하기</button>
                    </div>
                </div>
            </div>
            }
        </div>
    );
};
export default CpointInfo;